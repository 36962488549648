<template>
  <div class="search-surnames-results-container block-results">
    <template v-if="resultsLength">
      <div class="search-results">
        <search-surnames-result-item
          v-for="surname in surnameClansSearchListState"
          :surname="surname"
          :key="surname.name_ch"
          :search-text="searchText"
        ></search-surnames-result-item>
      </div>
    </template>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

import SearchSurnamesResultItem from './SearchSurnamesResultItem';

export default {
  props: {
    searchText: String,
  },
  computed: {
    ...mapGetters(['surnameClansSearchListState']),
    resultsLength() {
      return this.surnameClansSearchListState.length;
    },
    meta() {
      return {offset: 0, limit: this.resultsLength, total_count: this.resultsLength};
    },
  },
  components: {SearchSurnamesResultItem},
};
</script>

<style lang="scss" scoped></style>
