<template>
  <stand-out-block class="search-surnames-result-item">
    <div class="content">
      <div class="data">
        <div class="title">
          <div class="chinese-character">{{ chineseSurname }}</div>
          <div class="pinyin" v-if="pinyin">[{{ pinyin }}]</div>
        </div>
        <div class="meaning" v-if="surname.meaning">
          <p>Meaning: {{ surname.meaning }}</p>
        </div>
        <p v-if="dialects" class="supplemental">
          <mcr-wiki-glossary-link naturalId="variant-character-dialects" :is-help-text="true"
            >Dialects</mcr-wiki-glossary-link
          ><span>: {{ dialects }}</span>
        </p>
        <p v-if="countries" class="supplemental">
          <mcr-wiki-glossary-link naturalId="variant-character-countries" :is-help-text="true"
            >Regions</mcr-wiki-glossary-link
          ><span>: {{ countries }}</span>
        </p>
        <router-link class="action-link" :to="surnameDetailsRoute"
          >Explore {{ surname.name_hant || surname.name_ch }} {{ surname.pinyin }} meaning & origins</router-link
        >
      </div>
      <div class="meta">
        <div class="meta-item">
          <records-icon></records-icon>
          {{ surname.meta.zupus_count }} records
        </div>
        <div class="meta-item">
          <earth-icon></earth-icon>
          {{ surname.meta.spellings_count }} variants
        </div>
        <div class="meta-item">
          <places-icon></places-icon>
          {{ surname.meta.places_count }} villages
        </div>
      </div>
    </div>
  </stand-out-block>
</template>

<script>
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import {isChineseText} from '@common/utils/utils';
import RecordsIcon from 'vue-material-design-icons/ClipboardTextSearchOutline';
import EarthIcon from 'vue-material-design-icons/Earth';
import PlacesIcon from 'vue-material-design-icons/MapSearchOutline';

const mcrWikiGlossaryLink = () => import('@common/elements/glossary/mcrWikiGlossaryLink');

export default {
  props: {
    surname: Object,
    searchText: String,
  },
  computed: {
    pinyin() {
      if (isChineseText(this.searchText)) {
        return this.surname.pinyin;
      }
      return this.surname.pinyin.toLowerCase() === this.searchText.toLowerCase()
        ? this.surname.pinyin
        : `${this.surname.pinyin} / ${this.searchText}`;
    },
    chineseSurname() {
      const nameCh = this.surname.name_ch;
      const nameHant = this.surname.name_hant;
      return nameHant && nameCh !== nameHant ? `${nameCh} / ${nameHant}` : nameCh;
    },
    surnameDetailsRoute() {
      return {name: 'search-tool-surname-detail', query: {word: this.surname.name_hant || this.surname.name_ch}};
    },
    matchedSpellings() {
      const searchTextLower = this.searchText.toLowerCase();
      return this.surname.meta.spellings.filter(s => s.spelling.toLowerCase() === searchTextLower);
    },
    dialects() {
      const items = this.matchedSpellings.map(s => s.dialect).filter(d => !!d);
      return this.getDisplayItems(items);
    },
    countries() {
      const items = this.matchedSpellings.map(s => s.place).filter(p => !!p);
      return this.getDisplayItems(items);
    },
  },
  methods: {
    getDisplayItems(items) {
      const maxLen = 8;
      if (items.length <= maxLen) {
        return items.join(', ');
      }
      const shownItems = items.slice(0, maxLen);
      const moreCount = items.length - maxLen;
      return `${shownItems.join(', ')}, and ${moreCount} more`;
    },
  },
  components: {StandOutBlock, RecordsIcon, EarthIcon, PlacesIcon, mcrWikiGlossaryLink},
};
</script>

<style lang="scss" scoped>
$meta-background-color: #f1eeeb;
$meta-border-color: #e3e3dc;

.stand-out-block.search-surnames-result-item {
  padding: 0;
  margin-bottom: 25px;
  border: 1px solid $neutral-300;
  overflow: hidden;

  .router-link {
    text-decoration: none;
    display: block;
    &:hover {
      .action-link {
        text-decoration: underline;
      }
    }
  }

  .content {
    display: flex;
    background: $background-light;
    justify-content: space-between;

    .data {
      padding: 10px 20px 20px;
      width: 100%;

      .title {
        display: flex;
        align-items: center;

        .chinese-character {
          font-size: 3em;
        }

        .pinyin {
          margin-left: 15px;
          margin-bottom: -1.5em;
        }
      }

      .meaning {
        color: $neutral-500;

        p {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
        }
      }
      .supplemental,
      .supplemental a {
        color: $neutral-500;
      }
      .action-link {
        font-weight: bold;
      }
    }

    .meta {
      color: $text-alternate-color;
      display: flex;
      flex-direction: column;
      padding: 25px;
      background: $meta-background-color;
      border-left: 1px solid $meta-border-color;
      width: 150px;
      min-width: 150px;
      max-width: 150px;

      .meta-item {
        display: flex;
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .material-design-icon {
          margin-right: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-phablet) {
  .stand-out-block.search-surnames-result-item .content {
    flex-direction: column;
    .data {
      width: auto;
      .meaning {
        margin-bottom: 40px;
      }
    }
    .meta {
      min-width: 0;
      width: auto;
      max-width: 100%;
      border-left: 0;
      border-top: 1px solid $meta-border-color;
    }
  }
}
</style>
