export default {
  description: '{{ description }}',
  dialects: ['{{ item }}'],
  dialects_list: [
    {
      name: '{{item.name}}',
      slug: '{{item.slug}}',
    },
  ],
  name: '{{ name }}',
  places: [
    {
      id: '{{ item.id }}',
      pinyin: '{{ item.pinyin }}',
    },
  ],
  ancestral_places: [
    {count: '{{ ancestral_places.0.count }}', id: 1, pinyin: 'Guangdong'},
    {count: '{{ ancestral_places.1.count }}', id: 2, pinyin: 'Fujian'},
  ],
  related_surnames: ['{{ item }}'],
  country_code: 'US',
};
