<template>
  <stand-out-block-image class="section-places-item" :label="label" :imgSrc="imgSrc" :img-height="170">
    <template slot="description">
      <div v-if="!clanId && !clanPinyin">Loading...</div>
      <router-link v-else-if="matchesCount" :to="toSearch"
        >View {{ matchesCount }} ancestral locations in {{ placeName }}</router-link
      >
      <div v-else class="link-container">
        <span class="supplemental">No {{ placeName }} locations for this name.<br /></span>
        <router-link :to="toSearch">Browse all ancestral places</router-link>
      </div>
    </template>
  </stand-out-block-image>
</template>

<script>
import StandOutBlockImage from '@common/elements/layouts/StandOutBlockImage';

export default {
  props: {
    placeName: String,
    placeId: [Number, String],
    imgSrc: String,
    clanId: String,
    clanPinyin: String,
    matchesCount: [Number, String],
  },
  computed: {
    toSearch() {
      const surnameQuery = this.clanId ? {surname: this.clanId} : {surname_name: this.clanPinyin};
      const query = this.matchesCount ? {...surnameQuery, place: this.placeId} : {place: this.placeId};
      return {name: 'search-tool-village', query};
    },
    label() {
      return `${this.placeName} Province`;
    },
  },
  components: {StandOutBlockImage},
};
</script>

<style lang="scss" scoped>
.section-places-item {
  .link-container {
    .supplemental {
      color: $neutral-500;
    }
  }
}
</style>
