<template>
  <div class="section-places">
    <h5>Where do the {{ pinyin }}s come from?</h5>
    <p>
      Most {{ pinyin }}s who left China and settled overseas in the past 400 years likely came from the provinces of
      Guangdong and Fujian.
    </p>
    <div class="places-container">
      <section-places-item
        v-for="place in placesDisplay"
        :key="place.id"
        :place-name="place.pinyin"
        :place-id="place.id"
        :img-src="place.imgSrc"
        :clan-id="clanId"
        :clan-pinyin="pinyin"
        :matches-count="place.count"
      ></section-places-item>
    </div>
  </div>
</template>

<script>
import SectionPlacesItem from './SectionPlacesItem';

export default {
  props: {
    pinyin: String,
    clanId: String,
    places: Array,
  },
  computed: {
    imgSourcesByPinyin() {
      return {
        guangdong: this.$getAsset('/assets/services/places/Guangdong-map-3-highlighted.png'),
        fujian: this.$getAsset('/assets/services/places/Fujian-map-2-highlighted.png'),
      };
    },
    placesDisplay() {
      const displayPlacesOrder = ['guangdong', 'fujian'];
      if (!this.places.length) {
        return displayPlacesOrder.map(pinyin => {
          return {imgSrc: this.imgSourcesByPinyin[pinyin], pinyin};
        });
      }
      const places = this.places.map(item => {
        return {...item, imgSrc: this.imgSourcesByPinyin[item.pinyin.toLowerCase()]};
      });
      return displayPlacesOrder.map(pinyin => places.find(item => item.pinyin.toLowerCase() === pinyin));
    },
  },
  components: {SectionPlacesItem},
};
</script>

<style lang="scss" scoped>
.places-container {
  display: flex;
  justify-content: space-between;
  margin-top: 28px;

  .section-places-item {
    width: 48.5%;
  }
  @media only screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .section-places-item {
      width: auto;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}
</style>
