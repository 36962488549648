<template>
  <dialect-base-page>
    <template v-slot:page-not-found>
      <page-not-found></page-not-found>
    </template>
    <template v-slot:cta>
      <cta-block-compare-plans></cta-block-compare-plans>
    </template>
  </dialect-base-page>
</template>

<script>
import DialectBasePage from '@common/pages/dialects/DialectBasePage';

import CtaBlockComparePlans from '@/components/common/cta/ctaBlockComparePlans.vue';

import PageNotFound from '@/components/page.not.found';

export default {
  components: {CtaBlockComparePlans, PageNotFound, DialectBasePage},
  name: 'DialectPage',
};
</script>

<style scoped lang="scss">
.dialect-page {
  .engaging-stand-out-block {
    margin: 48px 0;
  }
}
</style>
