<template>
  <section-related
    title="Related Surnames: "
    :items="surnames"
    :is-template="isTemplate"
    prerender-list-name="related_surnames"
  >
    <router-link slot="item" slot-scope="{item}" :to="{name: 'search-tool-surname-detail', query: {word: item}}">
      {{ item }}</router-link
    >
  </section-related>
</template>

<script>
import SectionRelated from '@common/elements/layouts/SectionRelated';

export default {
  components: {SectionRelated},
  props: {
    surnames: Array,
    isTemplate: Boolean,
  },
};
</script>

<style lang="scss" scoped></style>
