<template>
  <metadata-table class="surname-variation-list">
    <template v-slot:rows>
      <detail-item
        v-for="value in variations"
        v-prerender-for="{listName: prerenderListName, valueName: 'dialect'}"
        :key="value.object_id"
        :label="value.region.english_name"
      >
        <span
          v-for="(spelling, index) in value.spellings"
          v-prerender-for="{listName: 'dialect.spellings', valueName: 'spelling'}"
        >
          <router-link :to="getVariantRoute(spelling)">{{ spelling }}</router-link>
          <span v-if="!isLastItem(index, value.spellings) || isTemplate" v-prerender-if="'not forloop.last'">, </span>
        </span>
      </detail-item>
    </template>
  </metadata-table>
</template>

<script>
import MetadataTable from '@common/elements/layouts/MetadataTable';
import detailItem from '@common/elements/layouts/detailsItem';

export default {
  props: {
    variations: Array,
    prerenderListName: String,
    isTemplate: Boolean,
  },
  methods: {
    getVariantRoute(value) {
      return {name: 'search-tool-surname-detail', query: {word: value}};
    },
    isLastItem(index, items) {
      return index === items.length - 1;
    },
  },
  components: {detailItem, MetadataTable},
};
</script>

<style scoped lang="scss">
.surname-variation-list {
  margin-top: 16px;
}
</style>
