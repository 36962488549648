<template>
  <div class="search-join-offer-page">
    <div class="content-container" v-if="loading">
      <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
    </div>
    <div class="content-container error text-lg" v-else-if="error">
      Something went wrong while searching. Please try again later.
    </div>
    <div class="content-container" v-else>
      <div class="heading-1 heading-2-mobile">{{ mainTitle }}</div>
      <div class="records-container" v-if="recordItems.length">
        <div class="record-item" v-for="item in recordItems" :key="item.category">
          <img
            v-if="item.wiki_article && item.wiki_article.image"
            :src="getThumbnailSrc(item.wiki_article.image)"
            width="90"
            height="70"
          />
          <div>{{ item.persons_count.toLocaleString('en') }} {{ item.explicit_name }}</div>
        </div>
      </div>
      <div class="go-back-container" v-else>
        <a @click="goBack" class="text-md"><arrow-left-icon :size="20" />{{ backLinkLabel }}</a>
      </div>
      <div class="button-container">
        <mcr-button @click="startSubscription" :loading="stripeLoading">{{ buttonLabel }}</mcr-button>
        <div class="text-sm">{{ buttonSubLabel }}</div>
      </div>

      <div class="login-link-cta" v-if="!userIsLoggedInState">
        Already a member? <router-link :to="loginRoute">Login.</router-link>
      </div>
    </div>
    <div class="image-container">
      <img :src="$getAsset('/assets/services/surname-search-records-found.png')" width="760" height="690" />
    </div>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import {NetworkAPIThumbnailURL} from '@common/network/network.utils';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import orderBy from 'lodash/orderBy';
import ArrowLeftIcon from 'vue-material-design-icons/ArrowLeft';
import {mapGetters} from 'vuex';

export default {
  components: {McrButton, ArrowLeftIcon},
  data() {
    return {
      loading: true,
      stripeLoading: false,
      error: false,
      recordItems: [],
      totalCount: 0,
    };
  },
  created() {
    this.$stripe.init();
    if (!this.$store.getters.subscriptionPlansState.length) {
      this.$store.dispatch('fetchSubscriptionPlansAction');
      if (this.$store.getters.userIsLoggedInState) {
        this.$store.dispatch('fetchSubscriptionsAction');
      }
    }
    this.fetchRecordsMeta();
  },
  computed: {
    ...mapGetters([
      'isTrialEligibleState',
      'standardYearlyPlanState',
      'searchCategoriesListState',
      'userIsLoggedInState',
    ]),
    firstName() {
      return this.$route.query.first_name || '';
    },
    surname() {
      return this.$route.query.surname || '';
    },
    mainTitle() {
      const records = this.totalCount === 1 ? 'record' : 'records';
      const fullName = [this.firstName, this.surname].join(' ').trim();
      return `${this.totalCount.toLocaleString('en')} ${records} found of ${fullName}`;
    },
    buttonLabel() {
      return this.isTrialEligibleState ? 'Start a free trial to view records' : 'Become a Member to view records';
    },
    buttonSubLabel() {
      const cost = this.standardYearlyPlanState ? this.standardYearlyPlanState.cost : '';
      const costText = `$${cost}/year`;
      const trialDays = this.standardYearlyPlanState ? this.standardYearlyPlanState.trial_days : 0;
      return this.isTrialEligibleState ? `${costText} after ${trialDays} day trial` : costText;
    },
    searchUrl() {
      const query = {first_name: this.firstName, surname: this.surname, tab: this.$route.query.tab};
      return this.$router.resolve({name: 'search-all-records', query}).href;
    },
    backLinkLabel() {
      return this.surname ? `Back to ${this.surname} details page` : 'Back';
    },
    loginRoute() {
      return {name: 'login', query: {redirect: this.searchUrl}};
    },
  },
  methods: {
    fetchRecordsMeta() {
      this.loading = true;
      const categoriesPromise = this.searchCategoriesListState.length
        ? Promise.resolve({objects: this.searchCategoriesListState})
        : this.$store.dispatch('fetchSearchCategoriesListAction');
      const searchPromise = this.$store.dispatch('searchAllRecordsSilentAction', {
        first_name: this.firstName,
        surname: this.surname,
        only_meta: true,
      });
      Promise.all([categoriesPromise, searchPromise])
        .then(responses => {
          this.totalCount = responses[1].meta.total_count;
          this.setRecordItems(responses[0].objects, responses[1].meta);
        })
        .catch(error => {
          console.log(error);
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setRecordItems(categories, meta) {
      this.recordItems = orderBy(categories, ['is_highlighted', 'priority'], ['desc', 'asc'])
        .map(category => {
          const facet = meta.facets.category_id[category.id];
          return facet ? {...category, persons_count: facet.count} : null;
        })
        .filter(item => item && item.persons_count);
    },
    startSubscription() {
      AnalyticsMainHandler.trackStartStandardSubscriptionClick();
      if (this.$store.getters.userIsLoggedInState) {
        return this.goToStripe();
      }
      return this.goToRegister();
    },
    goToStripe() {
      this.stripeLoading = true;
      const cancelUrl = this.$router.resolve({name: this.$route.name, query: this.$route.query}).href;
      this.$store
        .dispatch('createSubscriptionCheckoutSessionAction', {
          plan_id: this.standardYearlyPlanState.id,
          success_url: this.searchUrl,
          cancel_url: cancelUrl,
        })
        .then(res => {
          if (res.session_id) {
            this.$stripe.redirectToCheckout({sessionId: res.session_id});
          }
        })
        .catch(error => {
          this.stripeLoading = false;
          if (error && error.response && error.response.data) {
            this.$toasted.error(error && error.response && error.response.data);
          }
        });
    },
    goToRegister() {
      const query = {
        billing_period: this.standardYearlyPlanState.billing_period,
        autostart_plan: this.standardYearlyPlanState.id,
        redirect: this.searchUrl,
      };
      const redirectUrl = this.$router.resolve({name: 'subscription-plans', query}).href;
      this.$router.push({name: 'register', query: {redirect: redirectUrl}});
    },
    goBack() {
      this.$router.back();
    },
    getThumbnailSrc(image) {
      return NetworkAPIThumbnailURL + '?url=' + encodeURIComponent(image) + '&width=270&quality=80';
    },
  },
  name: 'SearchJoinOfferPage',
};
</script>

<style lang="scss" scoped>
.search-join-offer-page {
  padding-bottom: 130px;
  display: flex;

  .content-container {
    padding: 88px 100px 0 120px;
    width: 520px;
    flex-shrink: 0;

    .records-container {
      display: flex;
      flex-wrap: wrap;
      column-gap: 32px;
      row-gap: 24px;
      margin: 40px 0;

      .record-item {
        flex-shrink: 0;
        width: 147px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        row-gap: 10px;
        color: $neutral-800;

        img {
          width: 90px;
          height: 70px;
          object-fit: cover;
        }
      }
    }
    .go-back-container {
      margin: 24px 0 38px;

      a {
        display: flex;
        align-items: center;
        column-gap: 5px;
      }
    }

    .button-container {
      display: flex;
      column-gap: 12px;
      align-items: center;

      .text-sm {
        color: $neutral-600;
      }
    }

    .login-link-cta {
      color: $neutral-600;
      margin-top: 20px;
    }
  }
}

@media only screen and (min-width: $main-menu-breakpoint) {
  .search-join-offer-page {
    max-width: 1500px;
    align-self: center;
  }
}
@media only screen and (max-width: $main-menu-breakpoint) {
  .search-join-offer-page {
    flex-direction: column;

    .content-container {
      padding: 44px 0;
      width: auto;

      .heading-2-mobile {
        margin: 0 16px;
      }

      .records-container {
        flex-wrap: nowrap;
        overflow-y: auto;
        column-gap: 8px;
      }
      .go-back-container {
        margin: 16px 16px 32px;
      }

      .button-container {
        flex-direction: column;
        row-gap: 12px;
        align-items: flex-start;
        padding: 0 16px;
      }
    }
  }
}
</style>
