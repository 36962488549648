<template>
  <div class="section-places-map">
    <h5>{{ pinyin }} surname associated countries</h5>
    <section-related-regions
      class="sub-section"
      title=""
      :places="allPlaces"
      :is-template="isTemplate"
    ></section-related-regions>
    <div class="map-container">
      <data-map
        :data="places"
        :one-color="true"
        :get-text-display="mapGetTextDisplay"
        :click-handler="clickHandler"
      ></data-map>
    </div>
  </div>
</template>

<script>
import DataMap from '@common/elements/maps/DataMap';
import {getPlaceDetailRoute} from '@common/utils/utils.routes';

import SectionRelatedRegions from './SectionRelatedRegions';

export default {
  props: {
    places: Array,
    allPlaces: Array,
    pinyin: String,
    isTemplate: Boolean,
  },
  methods: {
    mapGetTextDisplay(item) {
      return item.pinyin;
    },
    clickHandler(data) {
      const country = this.places.find(item => item.official_code === data.points[0].location);
      this.$router.push(getPlaceDetailRoute(country.id, country.pinyin));
    },
  },
  name: 'SectionPlacesMap',
  components: {SectionRelatedRegions, DataMap},
};
</script>

<style scoped lang="scss">
.section-places-map {
  .sub-section {
    margin: 10px 0;
  }
  .map-container {
    position: relative;
    width: 100%;
    padding-top: 79.17%;

    .data-map::v-deep .map {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
