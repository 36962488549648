<template>
  <div class="section-clans">
    <h5>How do you write {{ surname }} in Chinese?</h5>
    <div v-if="count > 1">
      {{ surname }} is a Chinese surname that can be written in {{ count }} different ways in Chinese:
    </div>

    <search-surnames-results-container
      v-if="count"
      :search-text="surname"
      v-prerender-for="{listName: 'clans', valueName: 'clan'}"
    ></search-surnames-results-container>
    <loading-animated v-else-if="loading">
      <vcl-table slot-scope="props" :primary="props.primary" :secondary="props.secondary"></vcl-table>
    </loading-animated>

    <div class="disclaimer supplemental italic">
      <map-icon :size="30"></map-icon>Pick a Chinese clan above to see where associated {{ surname }}s come from in
      China
    </div>
  </div>
</template>

<script>
import LoadingAnimated from '@common/elements/loading/LoadingAnimated';
import {VclTable} from 'vue-content-loading';
import MapIcon from 'vue-material-design-icons/MapSearchOutline';

import SearchSurnamesResultsContainer from './clans/SearchSurnamesResultsContainer';

export default {
  props: {
    surname: String,
  },
  computed: {
    count() {
      const list = this.$store.getters.surnameClansSearchListState;
      return list ? list.length : '';
    },
    loading() {
      return this.$store.getters.surnameClansSearchLoadingState;
    },
  },
  components: {SearchSurnamesResultsContainer, MapIcon, LoadingAnimated, VclTable},
};
</script>

<style lang="scss" scoped>
.search-surnames-results-container {
  margin-top: 30px;
}
.disclaimer,
.material-design-icon {
  display: flex;
  align-items: center;
}
.material-design-icon {
  margin-right: 15px;
}
.loading-animated {
  margin: 30px 0;
}
</style>
