<template>
  <div class="section-pronunciation section-spelling-country">
    <h5>{{ pinyin }} Surname Spellings by Region</h5>
    <div>This surname can be spelled differently depending on where in the world the surname bearer migrated to.</div>
    <surname-variation-list
      :is-template="isTemplate"
      :variations="variations"
      prerender-list-name="dialects.by_country"
    ></surname-variation-list>
  </div>
</template>

<script>
import SurnameVariationList from './surnameVariationList';

export default {
  components: {SurnameVariationList},
  props: {
    pinyin: String,
    variations: Array,
    isTemplate: Boolean,
  },
};
</script>

<style scoped></style>
