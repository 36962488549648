<template>
  <div class="section-related">
    <span
      ><strong>{{ title }}</strong></span
    >
    <span v-prerender-for="{listName: prerenderListName, valueName: 'item'}">
      <template v-for="(item, index) in items">
        <slot name="item" :item="item">
          <span>{{ item }}</span>
        </slot>
        <span v-if="!isLastItem(index) || isTemplate" v-prerender-if="'not forloop.last'">, </span>
      </template>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    items: Array,
    prerenderListName: String,
    isTemplate: Boolean,
  },
  computed: {
    itemsLength() {
      return this.items.length;
    },
  },
  methods: {
    isLastItem(index) {
      return index === this.itemsLength - 1;
    },
  },
};
</script>

<style scoped></style>
