<template>
  <div class="section-external-link">
    <h5>Read more on our Blog: Traceable</h5>
    <div class="image-container" v-for="link in links" :key="link.title" @click="openNewTab(link.full_link)">
      <img class="image lazyload" :data-src="link.image_url" width="800" height="530" />
      <h4>{{ link.title }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    links: Array,
  },
  methods: {
    openNewTab(url) {
      window.open(url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
.section-external-link {
  .image-container {
    position: relative;
    cursor: pointer;
    margin-top: 12px;

    h4 {
      position: absolute;
      top: 20px;
      left: 20px;
      margin: 0;
      color: $off-white;
      font-weight: 600;
    }

    .image {
      filter: brightness(50%);
    }

    &:hover {
      h4 {
        text-decoration: underline;
      }
    }
  }
}
</style>
