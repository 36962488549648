<template>
  <div class="surname-search-form">
    <input ref="surnameInput" v-model="surname" :placeholder="placeholder" @keyup.enter="goToSurnameSearch" />
    <mcr-button @click="goToSurnameSearch" :loading="surnameExistsLoadingState">{{ buttonLabel }}</mcr-button>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import capitalize from 'lodash/capitalize';
import {mapGetters} from 'vuex';

export default {
  props: {
    placeholder: {type: String, default: 'My surname'},
    buttonLabel: {type: String, default: 'Explore'},
  },
  data() {
    return {
      surname: '',
    };
  },
  computed: {
    ...mapGetters(['surnameExistsState', 'surnameExistsLoadingState']),
  },
  methods: {
    goToSurnameSearch() {
      const {surnameInput} = this.$refs;
      surnameInput.setCustomValidity('');

      if (!this.surname) {
        surnameInput.setCustomValidity('Please, enter a surname.');
        surnameInput.reportValidity();
        return;
      }
      this.surname = capitalize(this.surname);
      return this.$store.dispatch('getSurnameExistsAction', this.surname).then(() => {
        this.afterSearchRan();
      });
    },
    afterSearchRan() {
      if (this.surnameExistsState) {
        return this.goToSurnamePage();
      }
      this.goToSurnameNotFoundPage();
    },
    goToSurnamePage() {
      this.$router.push({name: 'search-tool-surname-detail', query: {word: this.surname}}).catch(() => {});
    },
    goToSurnameNotFoundPage() {
      this.$store.commit('setSurnamesSearchFormTextState', this.surname);
      this.$router.push({name: 'search-tool-surname', query: {q: this.surname}});
    },
  },
  components: {McrButton},
};
</script>

<style scoped lang="scss">
.surname-search-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: calc(100% - 90px);
  }
  .mcr-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 90px;
  }
}
</style>
