<template>
  <div class="data-map">
    <div class="map" :id="mapId"></div>
  </div>
</template>

<script>
import {getRandomString} from '@common/utils/utils';
import Plotly from 'plotly.js-geo-dist-min';

export default {
  props: {
    data: Array,
    zmin: {type: Number, default: 0},
    zmax: {type: Number, default: 5000000},
    oneColor: {type: Boolean, default: false},
    getTextDisplay: {type: Function},
    clickHandler: {type: Function},
  },
  mounted() {
    const BACKGROUND_COLOR = '#ece8e4';
    const BORDERS_COLOR = '#D1D5DB';
    const DATA_BORDERS_COLOR = '#9CA3AF';
    const LAND_COLOR = '#EBE8E3';
    const GRADIENT_START_COLOR = '#F8EAE8';
    const GRADIENT_END_COLOR = '#BB4444';
    const OCEAN_COLOR = '#A9D3DE';
    const TEXT_COLOR = '#4B5563';

    const z = this.oneColor ? this.data.map(item => this.zmax) : this.data.map(item => item.est_population);

    var data = [
      {
        type: 'choropleth',
        colorscale: [
          [0, GRADIENT_START_COLOR],
          [1, GRADIENT_END_COLOR],
        ],
        geojson: process.env.VUE_APP_COUNTRIES_GEO_JSON,
        featureidkey: 'id',
        locations: this.data.map(item => item.official_code),
        z: z,
        zmax: this.zmax,
        zmin: this.zmin,
        text: this.data.map(this.getTextDisplay),
        hoverinfo: 'text',
        colorbar: {
          title: {
            text: 'Estimated<br>Population',
            font: {
              family: 'Avenir, Arial, Helvetica, sans-serif',
              size: 14,
              color: TEXT_COLOR,
            },
          },
          tickcolor: TEXT_COLOR,
          tickfont: {
            family: 'Avenir, Arial, Helvetica, sans-serif',
            size: 12,
            color: TEXT_COLOR,
          },
          len: 0.5,
          thickness: 20,
          thicknessmode: 'pixels',
          orientation: 'h',
          xanchor: 'center',
          yanchor: 'top',
        },
        marker: {
          line: {
            color: DATA_BORDERS_COLOR,
          },
        },
      },
    ];
    var layout = {
      title: {
        text: '',
      },
      margin: {t: 0, r: 0, b: 0, l: 0},
      autosize: true,
      geo: {
        projection: {
          type: 'mercator',
        },
        lataxis: {
          range: [-70, 80],
        },
        center: {lon: 10, lat: 30},
        showcoastlines: true,
        coastlinecolor: BORDERS_COLOR,
        showland: true,
        landcolor: LAND_COLOR,
        showocean: true,
        oceancolor: OCEAN_COLOR,
        showlakes: false,
        showrivers: false,
        showcountries: true,
        countrycolor: BORDERS_COLOR,
        framecolor: BACKGROUND_COLOR,
        bgcolor: BACKGROUND_COLOR,
      },
      paper_bgcolor: BACKGROUND_COLOR,
    };
    const config = {responsive: true, displayModeBar: false};

    Plotly.newPlot(this.mapId, data, layout, config);

    if (this.clickHandler) {
      const mapDiv = document.getElementById(this.mapId);
      mapDiv.on('plotly_click', this.clickHandler);
    }
  },
  computed: {
    mapId() {
      return getRandomString();
    },
  },
  name: 'DataMap',
};
</script>

<style scoped lang="scss">
.data-map {
  margin-top: 10px;
  touch-action: none;

  &::v-deep {
    .infolayer {
      display: none;
    }
  }
}
</style>
