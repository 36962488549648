<template>
  <surname-details-page v-if="isWordChinese"></surname-details-page>
  <surname-variant-details-page v-else></surname-variant-details-page>
</template>

<script>
import {isChineseText} from '@common/utils/utils';

import SurnameDetailsPage from '@/components/modules/surnameDetails/SurnameDetailsPage';
import SurnameVariantDetailsPage from '@/components/modules/surnameVariantDetails/SurnameVariantDetailsPage';

export default {
  computed: {
    isWordChinese() {
      return isChineseText(this.$route.query.word);
    },
  },
  components: {SurnameDetailsPage, SurnameVariantDetailsPage},
};
</script>

<style lang="scss" scoped></style>
