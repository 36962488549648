<template>
  <div class="section-surname-characters">
    <div class="wrapper">
      <span v-if="hasTraditional">Simplified</span>
      <surname-character :name-ch="simplified"></surname-character>
    </div>
    <div class="wrapper" v-if="hasTraditional">
      <span>Traditional</span>
      <surname-character :name-ch="traditional"></surname-character>
    </div>
  </div>
</template>

<script>
import SurnameCharacter from './SurnameCharacter';

export default {
  props: {
    simplified: [String, Array],
    traditional: [String, Array],
  },
  computed: {
    hasTraditional() {
      return this.traditional && this.traditional !== this.simplified;
    },
  },
  components: {SurnameCharacter},
  name: 'SectionSurnameCharacters',
};
</script>

<style lang="scss" scoped>
.section-surname-characters {
  display: flex;
  column-gap: 40px;
  .wrapper {
    max-width: 45%;
  }
}

@media only screen and (max-width: $breakpoint-mobile) {
  .section-surname-characters {
    flex-wrap: wrap;
    column-gap: 32px;
  }
}
</style>
