<template>
  <div class="surname-character">
    <div class="character-container" v-for="word in nameCh" :key="word">
      <svg class="character" viewBox="0 0 100 100">
        <text x="50" y="77.5" text-anchor="middle">{{ word }}</text>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    nameCh: [String, Array],
  },
};
</script>

<style lang="scss" scoped>
.surname-character {
  margin-bottom: 15px;
  display: flex;
  max-width: 150px;

  .character-container {
    background-image: get-asset('/assets/surname_bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    svg.character {
      width: 100%;
      height: 100%;
      text {
        color: #000;
        font-size: 80px;
      }
    }
  }
}
</style>
