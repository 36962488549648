export default {
  dialects: {
    by_country: [
      {
        region: {
          english_name: '{{ dialect.region.english_name }}',
        },
        spellings: ['{{ spelling }}'],
      },
    ],
    by_dialect: [
      {
        region: {
          english_name: '{{ dialect.region.english_name }}',
        },
        spellings: ['{{ spelling }}'],
      },
    ],
  },
  external_links: [
    {
      full_link: '{{ external_links.0.full_link }}',
      image_url: '{{ external_links.0.image_url }}',
      title: '{{ external_links.0.title }}',
    },
  ],
  meaning: '{{ meaning }}',
  meta: {
    places: [
      {count: '{{ meta.places.0.count }}', id: 1, pinyin: 'Guangdong'},
      {count: '{{ meta.places.1.count }}', id: 2, pinyin: 'Fujian'},
    ],
  },
  clan_image_url: '{{ clan_image_url }}',
  name_ch: '{{ name_ch }}',
  name_ch_list: ['{{ name_ch }}'],
  name_hant: '{{ name_hant }}',
  name_hant_list: ['{{ name_hant }}'],
  object_id: '{{ object_id }}',
  origins: '{{ origins }}',
  pinyin: '{{ pinyin }}',
};
