<template>
  <div class="surname-search-form-block">
    <div class="heading-6">{{ title }}</div>
    <surname-search-form placeholder="Last name" button-label="Go"></surname-search-form>
  </div>
</template>

<script>
import SurnameSearchForm from '@common/elements/forms/surnameSearchForm';

export default {
  props: {
    title: {type: String, default: 'Look up another name'},
  },
  components: {SurnameSearchForm},
};
</script>

<style lang="scss" scoped>
.surname-search-form-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .heading-6 {
    margin-right: 24px;
  }
  .surname-search-form {
    flex-grow: 1;
  }
  @media only screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
    align-items: flex-start;
    .heading-6 {
      margin-bottom: 12px;
      margin-right: 0;
    }
    .surname-search-form {
      width: 100%;
    }
  }
}
</style>
