<template>
  <div class="section-description">
    <template v-if="isTemplate">
      <span v-prerender-if="'description'">{{ description }}</span>
      <span v-prerender-if="multipleClansTagged">{{ multipleClansTaggedText }}</span>
      <span v-prerender-if="onlyRegionTagged">{{ onlyRegionTaggedText }}</span>
      <span v-prerender-if="onlyDialectTagged">{{ onlyDialectTaggedText }}</span>
      <span v-prerender-if="regionDialectTagged">{{ regionDialectTaggedText }}</span>
    </template>
    <template v-else>
      <span v-if="description">{{ description }}</span>
      <span v-else-if="multipleClansTagged">{{ multipleClansTaggedText }}</span>
      <span v-else-if="onlyRegionTagged">{{ onlyRegionTaggedText }}</span>
      <span v-else-if="onlyDialectTagged">{{ onlyDialectTaggedText }}</span>
      <span v-else-if="regionDialectTagged">{{ regionDialectTaggedText }}</span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    surname: String,
    description: String,
    clans: Array,
    places: Array,
    dialects: Array,
    isTemplate: Boolean,
  },
  computed: {
    multipleClansTagged() {
      if (this.isTemplate) {
        return 'not description and clans|length != 1 and clans|length != 0';
      }
      return this.clans && this.clans.length > 1;
    },
    onlyRegionTagged() {
      if (this.isTemplate) {
        return 'not description and clans|length == 1 and places and not dialects';
      }
      return this.region && !this.dialect;
    },
    onlyDialectTagged() {
      if (this.isTemplate) {
        return 'not description and clans|length == 1 and not places and dialects';
      }
      return !this.region && this.dialect;
    },
    regionDialectTagged() {
      if (this.isTemplate) {
        return 'not description and clans|length == 1 and places and dialects';
      }
      return this.region && this.dialect;
    },
    region() {
      if (this.isTemplate) {
        return '{{ places.0.pinyin }}';
      }
      return this.places && this.places.length ? this.places[0].pinyin : null;
    },
    dialect() {
      if (this.isTemplate) {
        return '{{ dialects.0 }}';
      }
      return this.dialects && this.dialects.length ? this.dialects[0] : null;
    },
    meaning() {
      if (this.isTemplate) {
        return '{{ clans.0.meaning }}';
      }
      return this.clans && this.clans.length ? this.clans[0].meaning : null;
    },
    multipleClansTaggedText() {
      return `${this.surname} is a common last name found among Overseas Chinese communities around the world.
        In fact, "${this.surname}" is the transliteration of several different Chinese surnames.
        Its meaning varies depending on how it is written in Chinese, and which dialect it is pronounced in.`;
    },
    onlyRegionTaggedText() {
      return `${this.surname} is a last name commonly found in ${this.region} among its Chinese community.
        It is the transliteration of a Chinese surname meaning: ${this.meaning}.`;
    },
    onlyDialectTaggedText() {
      return `${this.surname} is the ${this.dialect} transliteration of a Chinese surname meaning: ${this.meaning}.`;
    },
    regionDialectTaggedText() {
      return `${this.surname} is a last name commonly found in ${this.region} among its Chinese community.
        It is the ${this.dialect} transliteration of a Chinese surname meaning: ${this.meaning}.`;
    },
  },
};
</script>

<style scoped></style>
