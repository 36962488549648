export default [
  {
    meaning: '{{ clan.meaning }}',
    meta: {
      spellings: [],
      places_count: '{{ clan.meta.places_count }}',
      spellings_count: '{{ clan.meta.spellings_count }}',
      zupus_count: '{{ clan.meta.zupus_count }}',
    },
    name_ch: '{{ clan.name_ch }}',
    name_hant: '{{ clan.name_hant }}',
    pinyin: '{{ clan.pinyin }}',
    priority: '{{ clan.priority }}',
  },
];
