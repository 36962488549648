<template>
  <div class="surname-search-form">
    <input
      v-if="surnameFirst"
      v-model="surname"
      v-capitalize
      placeholder="Last Name"
      class="input"
      @keyup.enter="goToSearch"
    />
    <input v-model="firstName" v-capitalize placeholder="First Name" class="input" @keyup.enter="goToSearch" />
    <input
      v-if="!surnameFirst"
      v-capitalize
      v-model="surname"
      placeholder="Last Name"
      class="input"
      @keyup.enter="goToSearch"
    />
    <mcr-button @click="goToSearch" rel="nofollow" :disabled="isButtonDisabled">{{ buttonLabel }}</mcr-button>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import AnalyticsMainHandler from '@common/utils/analytics/analytics.main';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';
import {TAB_ID_FULL_TEXT, TAB_ID_INDEXED_RECORDS} from '@common/utils/consts.search';
import {isChineseText} from '@common/utils/utils';

export default {
  props: {
    surnameInit: {type: String, default: ''},
    buttonLabel: {type: String, default: 'Find Records'},
    surnameFirst: {type: Boolean, default: false},
    smartRedirect: {type: Boolean, default: false},
    paywall: {type: Boolean, default: false},
    allowEmptySubmit: {type: Boolean, default: false},
  },
  data() {
    return {
      firstName: '',
      surname: this.surnameInit,
    };
  },
  computed: {
    isButtonDisabled() {
      if (this.allowEmptySubmit) {
        return false;
      }
      return !this.firstName && !this.surname;
    },
  },
  methods: {
    goToSearch() {
      if (this.isButtonDisabled) {
        return;
      }
      const firstName = this.firstName.trim();
      const surname = this.surname.trim();
      this.$emit('before-redirect');
      if (this.smartRedirect) {
        const tab = this.getTabByLanguage(firstName, surname);
        return tab ? this.redirectToUnifiedSearch(firstName, surname, tab) : this.languageError();
      }
      this.redirectToUnifiedSearch(firstName, surname, TAB_ID_INDEXED_RECORDS);
    },
    redirectToUnifiedSearch(firstName, surname, tab) {
      if (this.paywall && !this.$store.getters.userIsSubscribedState && !this.$store.getters.userIsStaffState) {
        return this.handlePaywall(firstName, surname, tab);
      }
      let query = {first_name: firstName, surname: surname, tab};
      if (tab === TAB_ID_FULL_TEXT) {
        query = {q: firstName, last_name: surname, tab};
      }
      this.$router.push({name: 'search-all-records', query});
    },
    getTabByLanguage(firstName, surname) {
      const isFirstNameChinese = firstName ? isChineseText(firstName) : false;
      const isSurnameChinese = surname ? isChineseText(surname) : false;
      if (!firstName && !surname) {
        return null;
      }
      if (!firstName || !surname) {
        return isFirstNameChinese || isSurnameChinese ? TAB_ID_FULL_TEXT : TAB_ID_INDEXED_RECORDS;
      }
      if ((isFirstNameChinese && !isSurnameChinese) || (!isFirstNameChinese && isSurnameChinese)) {
        return null;
      }
      if (isFirstNameChinese && isSurnameChinese) {
        return TAB_ID_FULL_TEXT;
      }
      return TAB_ID_INDEXED_RECORDS;
    },
    languageError() {
      this.$emit('language-error');
    },
    handlePaywall(firstName, surname, tab) {
      const toRoute = {name: 'search-join-offer', query: {first_name: firstName, surname, tab}};
      const fullPath = this.$router.resolve(toRoute).href;
      AnalyticsMainHandler.trackHitPaywallAction(
        'Submit surname search CTA form',
        toRoute.name,
        fullPath,
        getRoutePageName(this.$route)
      );
      this.$router.push(toRoute);
    },
  },
  name: 'SearchAllRecordsForm',
  components: {McrButton},
};
</script>

<style lang="scss" scoped>
.surname-search-form {
  display: flex;
  justify-content: space-between;

  > * {
    display: flex;
    width: 32%;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    flex-direction: column;
    > * {
      display: flex;
      width: 100%;
    }
    input {
      margin-bottom: 15px;
    }
  }
}
</style>
